<template>
  <v-container>
    <new-payment
      v-if="showNewModal"
      :currency="currency"
      :payments="payments"
      :pays="sale.payments"
      :pending="getLocalTotal - totalPays"
      @addPayment="addToPayment"
    />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <facture
              :online="online"
              :online-config="onlineConfig"
              :currency="currency || ''"
              :edit="true"
              :sale="sale"
              :sub-total="parseFloat(subTotal).toFixed(2)"
              :total-discount="parseFloat(totalDiscount).toFixed(2)"
              :total-price="parseFloat(totalPrice).toFixed(2)"
              :total-tax="parseFloat(totalTax).toFixed(2)"
              :total-delivery="parseFloat(totalDelivery).toFixed(2)"
              :total-perquisite="parseFloat(totalPerquisite).toFixed(2)"
              @updateData="update = false"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            md="8"
          >
            <app-data-table
              :headers="getTableColumns"
              :new-text="$vuetify.lang.t('$vuetify.actions.pay')"
              :hide-footer="!show"
              :items="sale.payments"
              :manager="'sales'"
              :sort-by="['name']"
              :sort-desc="[false, true]"
              :title="getTitle"
              :view-delete-button="show"
              :viewDeleteButtonObject="show"
              :view-edit-button="false"
              :view-new-button="show"
              :view-show-filter="false"
              :view-tour-button="show"
              csv-filename="Categories"
              multi-sort
              style="margin-top: 10px"
              @create-row="addNewPayment"
              @delete-row="deletePaymentHandler($event)"
            >
              <template v-slot:[`item.bank_payment`]="{ item }">
                {{ $vuetify.lang.t( '$vuetify.payment.' + item.bank_payment.method) }}
              </template>
              <template v-slot:[`item.cant`]="{ item }">
                <div>
                  {{ `${ parseFloat(item.cant).toFixed(2)}` }} {{currency ? currency : ''}}
                </div>
              </template>
              <template v-slot:[`item.cant_pay`]="{ item }">
                <div>
                  {{ parseFloat(item.cant_pay).toFixed(2) }}
                  {{
                    `${ item.currency_id !==null &&   item.currency_id !=='' ? item.currency.currency : currency
                    }`
                  }}
                </div>
              </template>
              <template v-slot:[`item.cant_back`]="{ item }">
                <div v-if="item.bank_payment.method === 'cash'">
                  <span v-if="item.cant_back > 0">
                    {{ parseFloat(item.cant_back).toFixed(2) }}
                    {{ `${item.cant_back ? currency : ''}` }}
                  </span>
                  <span v-else>
                    {{'0.00 ' + `${item.cant_back ? currency : ''}` }}
                  </span>
                </div>
                <div v-else>
                  --
                </div>
              </template>
            </app-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import NewPayment from './NewPay'
import Facture from '../facture/Facture'

export default {
  name: 'ListPay',
  components: {
    NewPayment,
    Facture
  },
  props: {
    online: {
      type: Boolean,
      default: false
    },
    onlineConfig: {
      type: Object,
      default: function () {
        return {}
      }
    },
    edit: {
      type: Boolean,
      default: false
    },
    totalPrice: {
      type: String,
      default: '0.00'
    },
    totalDelivery: {
      type: String,
      default: '0.00'
    },
    totalPerquisite: {
      type: String,
      default: '0.00'
    },
    totalTax: {
      type: String,
      default: '0.00'
    },
    totalDiscount: {
      type: String,
      default: '0.00'
    },
    subTotal: {
      type: String,
      default: '0.00'
    },
    sale: {
      type: Object,
      default: function () {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: true
    },
    currency: {
      type: String,
      default: ''
    },
    discounts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      search: '',
      cant: 0.0,
      totalPays: 0.0,
      maxLength: 100,
      minLength: 0,
      errorName: ''
    }
  },
  computed: {
    ...mapState('payment', ['payments', 'showNewModal', 'showEditModal', 'showShowModal', 'isTableLoading']),
    getTitle () {
      return this.show ? this.$vuetify.lang.t('$vuetify.variants.total_price') +
        ': ' + (parseFloat(this.getLocalTotal).toFixed(2)) + ' ' + this.currency
        : ''
    },
    getTableColumns () {
      const data = [
        {
          text: this.$vuetify.lang.t('$vuetify.payment.name'),
          value: 'bank_payment',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cant_pay'),
          value: 'cant_pay',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cant_charge'),
          value: 'cant',
          sortable: false
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cant_back'),
          value: 'cant_back',
          sortable: false
        }
      ]
      if (this.show) {
        data.push({
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        })
      }
      return data
    },
    getLocalTotal () {
      return parseFloat(this.totalPrice) + parseFloat(this.totalDelivery) + parseFloat(this.totalPerquisite)
    }
  },
  watch: {
    'sale.payments': function () {
      this.calcTotalPay()
    }
  },
  created () {
    if (this.show) {
      this.getPayments()
    }
    this.calcTotalPay()
  },
  methods: {
    ...mapActions('payment', ['toogleNewModal', 'openEditModal', 'openShowModal', 'getPayments', 'deletePayment']),
    addNewPayment () {
      const total = this.getLocalTotal
      if (this.totalPays < total) {
        this.toogleNewModal(true)
      } else {
        this.$Swal.fire({
          title: this.$vuetify.lang.t('$vuetify.titles.new', [this.$vuetify.lang.t('$vuetify.payment.name')]),
          text: this.$vuetify.lang.t('$vuetify.messages.warning_excess_money'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.$vuetify.lang.t('$vuetify.actions.accept'),
          confirmButtonColor: 'red'
        })
      }
    },
    deletePaymentHandler (item) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.pay')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(() => {
          if (this.sale.payments.length > 0) { this.sale.payments = this.sale.payments.filter(pym => pym !== item) }
          if (this.sale.pays.length > 0) { this.sale.pays = this.sale.pays.filter(pym => pym !== item) }
          this.calcTotalPay()
        })
    },
    addToPayment (pay) {
      this.sale.payments.push(pay)
      this.toogleNewModal(false)
      this.calcTotalPay()
    },
    openEditCant (item) {
      this.cant = item.cant
    },
    saveEditCant (item) {
      if (this.totalPays + item.cant <= this.getLocalTotal) {
        item.cant = this.cant
      } else {
        item.cant = parseFloat((this.getLocalTotal - this.totalPays).toString()) + parseFloat(item.cant)
      }
      item.cant = parseFloat(item.cant.toString()).toFixed(2)
      item.cant_back = item.currency.id !== '' ? item.cant_pay * item.currency.change - item.cant : item.cant_pay - item.cant
      this.calcTotalPay()
    },
    calcTotalPay () {
      this.totalPays = 0.0
      this.sale.payments.forEach(v => {
        this.totalPays += parseFloat(v.cant)
      })
    }
  }
}
</script>

<style scoped></style>
